@import 'imports';
/**
 * We keep a separate file as there might be
 * some style changes.
 */
@import 'tm';

.banner {
  margin-top: 62px;
}

.wrapper, .banner {
  // ERBT-5929: We override here the box-sizing property injected by Magenta header/footer
  // CSS that causes layout issues.
  box-sizing: content-box;
}

.container-content {
  max-width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;

  .container-content-main {
    max-width: 100%;
    margin-top: 83px;
    padding-bottom: 0px;
    position: relative;
  }
}

.purchase-option-block {
  .ivr-section {
    display: none !important;
  }

  .sms-section {
    p {
      display: inline-block;
    }
  }
}

@media (min-width: 768px) {
  .purchase-option-block {
    .sms-section {
      p {
        line-height: 60px;
      }
    }
  }

  .landing-promo-background {
    // ERBT-5737: Requirement to use different background image for TMAT.
    background: no-repeat center/cover url("../assets/tmat/img/onboarding-desktop-tmat.jpg") black;
  }
}

@media (max-width: 768px) {
  #etva {
    .etmobile {
      // Hide TMO/AT "Tinka" on mobile because there is no good place
      // we could put her where she does not overlap something else.
      .etvamobile {
        display: none !important;
      }
    }
  }

  .container-content {
    .container-content-main {
      margin-top: 55px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1160px) {
  .container-content-main {
    margin-top: 55px !important;
  }
}

.carousel-TextArea {
  h3 {
    bottom: 40%;
  }
}

.cdk-overlay-container {
  // ERBT-5603: Fix for displaying the pop-ups over the magenta header.
  z-index: 20000 !important;
}

.main-tabs {
  mat-tab-header, .mdc-tab-bar {
    display: none;
  }
}

/* Ensure compatibility with MDC-based tab headers */
.mat-mdc-tab.mdc-tab--active:hover .mdc-tab__text-label,
.mat-tab-group.mat-primary .mat-tab-label-active {
  color: transparent !important;
}

.mat-mdc-card-content, .mat-card-content {
  padding: 0 !important;
}

.last.register {
  span {
    color: white;
  }
}

/* Slide Toggle Compatibility */
.mat-slide-toggle, .mdc-switch {
  padding-bottom: 10px;
}

.toggle-button {
  padding: 25px;
  
  /* Ensure compatibility with MDC-based switches */
  .mdc-switch, .mat-slide-toggle {
    margin: 0px;
  }
}
