// Created a new files to keep all the  old tmobile styles which is imported by all other tmobile flavours.
@use '@angular/material' as mat;
// So that the new changes will not break other styles, later on when we adapt new style for other apps
// This file can be deleted. Also this is the copy of old tmde.scss

@import 'imports';

// TM DE palette
$brand-color: #E20074;

$primary-base-color: #E20074;
$primary-hover-color: #B2005C;
$primary-pressed-color: #B2005C;
$primary-text-color: #f4f4f4;

$secondary-base-color: #fff;
$secondary-hover-color: #f4f4f4;
$secondary-pressed-color: #f4f4f4;
$secondary-text-color: #040404;
$secondary-selected-color: $primary-base-color;

$border-color: #dcdcdc;
$font-color: #383838;
$footer-color: $brand-color;
$body-color: #ededed;

$mat-tmde-accent: (
  50: $secondary-base-color,
  100: $secondary-base-color,
  200: $secondary-base-color,
  300: $secondary-base-color,
  400: $secondary-base-color,
  500: $secondary-selected-color,
  600: $secondary-hover-color,
  700: $secondary-pressed-color,
  800: $secondary-pressed-color,
  900: $secondary-pressed-color,
  contrast: (
    50: $secondary-text-color,
    100: $secondary-text-color,
    200: $secondary-text-color,
    300: $secondary-text-color,
    400: $secondary-text-color,
    500: $secondary-text-color,
    600: $secondary-text-color,
    700: $secondary-text-color,
    800: $secondary-text-color,
    900: $secondary-text-color,
  )
);

$mat-tmde-primary: (
  50: $primary-base-color,
  100: $primary-base-color,
  200: $primary-base-color,
  300: $primary-base-color,
  400: $primary-base-color,
  500: $primary-base-color,
  600: $primary-hover-color,
  700: $primary-pressed-color,
  800: $primary-pressed-color,
  900: $primary-pressed-color,
  contrast: (
    50: $primary-text-color,
    100: $primary-text-color,
    200: $primary-text-color,
    300: $primary-text-color,
    400: $primary-text-color,
    500: $primary-text-color,
    600: $primary-text-color,
    700: white,
    800: white,
    900: white
  )
);

@import 'common';

@font-face {
  font-family: "TeleGroteskScreenLt";
  font-style: normal;
  src: url("../assets/tmcz/font/TeleGroteskScreen-Thin.eot?#iefix") format("embedded-opentype"),
  url("../assets/tmcz/font/TeleGroteskScreen-Thin.woff") format("woff"),
  url("../assets/tmcz/font/TeleGroteskScreen-Thin.ttf") format("truetype"),
  url("../assets/tmcz/font/TeleGroteskScreen-Thin.svg#TeleGroteskScreen-Thin") format("svg");
}

@font-face {
  font-family: "TeleGroteskScreen";
  src: url("../assets/tmcz/font/TeleGroteskScreen-Thin.eot?#iefix") format("embedded-opentype"),
  url("../assets/tmcz/font/TeleGroteskScreen-Thin.woff") format("woff"),
  url("../assets/tmcz/font/TeleGroteskScreen-Thin.ttf") format("truetype"),
  url("../assets/tmcz/font/TeleGroteskScreen-Thin.svg#TeleGroteskScreen-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "TeleGroteskScreenRg";
  src: url("../assets/tmcz/font/TeleGroteskScreen-Regular.eot?#iefix") format("embedded-opentype"),
  url("../assets/tmcz/font/TeleGroteskScreen-Regular.woff") format("woff"),
  url("../assets/tmcz/font/TeleGroteskScreen-Regular.ttf") format("truetype"),
  url("../assets/tmcz/font/TeleGroteskScreen-Regular.svg#TeleGroteskScreen-Regular") format("svg");
  font-style: normal;
}

@font-face {
  font-family: "TeleGroteskScreenBd";
  src: url("../assets/tmcz/font/TeleGroteskScreen-Bold.eot?#iefix") format("embedded-opentype"),
  url("../assets/tmcz/font/TeleGroteskScreen-Bold.woff") format("woff"),
  url("../assets/tmcz/font/TeleGroteskScreen-Bold.ttf") format("truetype"),
  url("../assets/tmcz/font/TeleGroteskScreen-Bold.svg#TeleGroteskScreen-Bold") format("svg");
}

@font-face {
  font-family: 'tsde-icon-new';
  src: url("../assets/tmcz/font/tsde-icon-new.eot");
  src: url("../assets/tmcz/font/tsde-icon-new.eot#iefix") format("embedded-opentype"),
  url("../assets/tmcz/font/tsde-icon-new.woff") format("woff"),
  url("../assets/tmcz/font/tsde-icon-new.ttf") format("truetype"),
  url("../assets/tmcz/font/tsde-icon-new.svg#fontello") format("svg");
  font-weight: normal;
  font-style: normal
}

$primary: mat.define-palette($mat-tmde-primary);
$accent: mat.define-palette($mat-tmde-accent);
$black-6-opacity: rgba(0, 0, 0, 0.06);
// Background palette for light themes.
$mat-light-theme-background-palette: (
  status-bar: mat.get-color-from-palette(mat.$grey-palette, 300),
  app-bar: rgba($brand-color, 1),
  background: mat.get-color-from-palette($accent, 50),
  hover: rgba($brand-color, 0.04),
  card: white,
  dialog: white,
  disabled-button: rgba(black, 0.12),
  raised-button: white,
  focused-button: $black-6-opacity,
  selected-button:  mat.get-color-from-palette(mat.$grey-palette, 300),
  selected-disabled-button:  mat.get-color-from-palette(mat.$grey-palette, 400),
  disabled-button-toggle:  mat.get-color-from-palette(mat.$grey-palette, 200),
);

$theme: mat.define-light-theme($primary, $accent);
@include mat.all-component-themes($theme);

body, p, h1, div, ul {
  font-family: 'TeleGroteskScreenRg', sans-serif;
  color: $font-color;
}

body {
  background-color: $body-color !important;
  line-height: 18px;
}

.mat-sidenav-container, .mat-drawer-container {
  background-color: $body-color !important;
}

h1.main-heading {
  font-family: 'TeleGroteskScreenLt', sans-serif;
  padding: 4% 0;
  max-width: 96%;
}

h1.time-setting {
  font-size: medium;
}

.mat-ink-bar, .mdc-tab-indicator__content--underline {
  background: $brand-color !important;
}


span.tag-line {
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  font-family: 'TeleGroteskScreenBd', sans-serif;
  text-transform: uppercase;
  color: mat.get-color-from-palette($accent, 50);
  width: 100%;
  text-align: right;
  position: absolute;
  right: 15px;
  top: 12px;
}

.top-bar {
  width: 100%;
  height: 48px;
  background: $brand-color;
  width: 100%;
  z-index: 1;
  position: relative;
  .inner {
    width: 100%;
    max-width: 945px;
    margin: 0 auto;
    position: relative;
  }
  span.logo {
    line-height: 24px;
    padding: 12px;
    display: inline-block;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
  }

  span.logo::before {
    transition: all 0.25s;
    content: '';
    display: inline-block;
    background: url('../assets/tmde/img/export/telekom_logo_white.svg') no-repeat;
    width: 24px;
    height: 24px;
    margin: 0 auto;
  }

}

.wrapper {
  overflow: visible;
}

.mat-tab-label, .mat-menu-item, .mdc-tab, .mdc-menu-item {
  font-family: 'TeleGroteskScreenRg', sans-serif !important;
  font-weight: normal;
  font-size: medium !important;

  &:focus {
    background: transparent;
  }
}

.mat-simple-snackbar, .mdc-snackbar__label {
  color: mat.get-color-from-palette($accent, 50) !important;
}

.bt-main {
  box-shadow: none !important;
  background-color: $body-color;
  border-radius: 4px !important;
  min-width: 88px !important;

  .mat-button-wrapper, .mdc-button__label {
    font-family: 'TeleGroteskScreenRg', sans-serif;
    font-size: large;
    font-weight: normal;

    span {
      vertical-align: inherit;
    }
  }
}

.bt-main:not(.mat-primary):not(.mdc-button--primary) {
  border: 1px solid #b2b2b2 !important;
}

.bt-main[color="primary"]:not(.mat-slide-toggle):not(.mdc-switch) {
  .mat-button-wrapper, .mdc-button__label {
    font-family: 'TeleGroteskScreenRg', sans-serif;
    color: mat.get-color-from-palette($accent, 50);
  }
}


.bt-close {
  background-size: 20px 20px !important;
  background-color: transparent;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-image: url('../assets/tmde/img/close.svg') !important;
}

.bt-delete {
  min-width: 45px !important;
  height: 45px;
  background-size: 30px 30px !important;
  background-color: transparent;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-image: url('../assets/tmde/img/trashcan.svg') !important;
}

app-artist-tone-list-item {
  mat-list-item, .mat-mdc-list-item {
    position: relative;
  }

  span.artist-img {
    display: block;
    height: 60px;
    width: 60px;
    background: $brand-color url('../assets/tmde/img/artist.svg') no-repeat center;
  }
}

.title {
  font-size: large;
}

app-caller-info {
  .mat-list-item .mat-list-item-content,
  .mat-mdc-list-item .mdc-list-item__content {
    background-color: mat.get-color-from-palette($accent, 50);
  }
}

app-setting-button {
  mat-list-item, .mat-mdc-list-item {
    background-color: mat.get-color-from-palette($accent, 50) !important;
  }

  .middle {
    min-width: calc(100% - 120px) !important;
  }
}

app-tone-list-item,
app-igt-list-item,
app-artist-tone-list-item,
app-call-party,
.scroller {
  mat-list-item,
  .mat-mdc-list-item {
    background-color: mat.get-color-from-palette($accent, 50) !important;
  }

  .mat-list-item .mat-list-item-content,
  .mat-mdc-list-item .mdc-list-item__content {
    overflow: hidden;

    .mat-list-text {
      overflow: hidden;
    }

    .expiry {
      color: #666666;
      text-align: left;
    }
  }

  .end {
    height: 60px;
  }

  mat-list-item {
    border-bottom: 1px solid $border-color;
  }

  .mat-mdc-list-item.selected,
  mat-list-item.selected {
    background-repeat: no-repeat !important;
    background-image: url('../assets/tmde/img/checkmark.svg') !important;
    background-position: right 25px top 50%;
  }

  .price {
    color: mat.get-color-from-palette($primary, 500);
  }

  .sms-code {
    a {
      color: mat.get-color-from-palette($primary, 500);
      text-decoration: none;
    }
  }

  .playback-button {
    color: mat.get-color-from-palette(map-get($primary, contrast), 700);
    background-image: url('../assets/tmde/img/play_2.svg') !important;
    z-index: 100;
  }

  .cover-anchor, .anchor {
    .stop {
      background-image: url('../assets/tmde/img/stop_2.svg') !important;
    }
    .loading {
      background-image: url('../assets/tmde/img/playback_spinner.svg') !important;
    }
  }

  span.icon-shuffle {
    display: block;
    height: 60px;
    min-width: 60px;
    background-size: 35px 29px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../assets/tmde/img/shuffle-act.svg') !important;
  }

}
app-igt-list-item {

  .mat-list-base,
  .mat-list-item,
  .mat-mdc-list-item,  // Added MDC support
  .mat-list-item .mat-list-item-content,
  .mat-mdc-list-item .mdc-list-item__content {  // Added MDC support
    height: 60px !important;
  }

  .end {
    height: 60px;
  }
}

.scroller .details-overlay {

  button.bt-main {
    background-color: $brand-color;
    color: mat.get-color-from-palette($accent, 50);
    border: 1px solid #B2005C !important;

    span {
      color: mat.get-color-from-palette($accent, 50);
    }
  }

  h2 {
    font-family: 'TeleGroteskScreenBd', sans-serif;
    text-transform: uppercase;
    color: mat.get-color-from-palette(map-get($primary, contrast), 700);
    line-height: 48px;
  }
}

mat-list-item.list-item-button,
.mat-mdc-list-item.list-item-button {  // Added MDC support
  border-bottom: 1px solid $border-color;

  span.shop {
    background-image: url('../assets/tmde/img/add.svg') !important;
    background-size: 34px 30px;
  }

  span.chevron {
    background-image: url('../assets/tmde/img/chevron.svg') !important;
    background-size: 16px 32px;
  }

  span.igt-disable {
    background-image: url('../assets/tmde/img/play_3-dis.svg') !important;
  }

  span.record {
    background-image: url('../assets/tmde/img/record.svg') !important;
  }

  span.record {
    background-image: url('../assets/tmde/img/message_1.svg') !important;
    background-size: 22px 39px;
  }

  span.igt {
    background-image: url('../assets/tmde/img/message_1.svg') !important;
    background-size: 22px 38px;
  }

  span.igt-active {
    background-image: url('../assets/tmde/img/message_1-act.svg') !important;
    background-size: 26px 38px;
  }

  span.rbt {
    background-image: url('../assets/tmde/img/song.svg') !important;
  }

  span.shuffle {
    background-image: url('../assets/tmde/img/shuffle-act.svg') !important;
  }

  span.add-caller {
    background-image: url('../assets/tmde/img/add.svg') !important;
    background-size: 28px 32px;
  }

  span.add-group {
    background-image: url('../assets/tmde/img/add.svg') !important;
    background-size: 36px 32px;
  }

  span.add-day-of-week {
    background-image: url('../assets/tmde/img/add.svg') !important;
    background-size: 30px 32px;
  }

  span.add-day-of-year {
    background-image: url('../assets/tmde/img/add.svg') !important;
    background-size: 30px 32px;
  }

  span.add-time-of-day {
    background-image: url('../assets/tmde/img/add.svg') !important;
    background-size: 34px 34px;
  }

  p.title {
    font-weight: normal;
    line-height: 25px;
  }

  .end {
    span.icon {
      background-position: 19px 14px !important;
    }
  }

}

mat-list-item.list-item-button:not(.list-item-button-setting),
.mat-mdc-list-item.list-item-button:not(.list-item-button-setting) {  // Added MDC support
  span.chevron {
    display: none !important;
  }
}


span.icon-app-sale-android, span.icon-app-sale-iphone  {
  background-image: url('../assets/tmde/img/sale-smartphone.svg') !important;
  background-position: center center;
  background-repeat: no-repeat;
  height: 70px;
  margin-right: 10px;
  width: 75px;
}

span.icon-phone-sale {
  background-image: url('../assets/tmde/img/sale-phone.svg') !important;
  background-position: center center;
  background-repeat: no-repeat;
  height: 70px;
  margin-right: 10px;
  width: 75px;
}

span.icon-sms-sale {
  background-image: url('../assets/tmde/img/sale-sms.svg') !important;
  background-position: center center;
  background-repeat: no-repeat;
  height: 70px;
  margin-right: 10px;
  width: 75px;
}

mat-sidenav {
  background-color: mat.get-color-from-palette($accent, 50) !important;
  height: 100%;

  mat-list-item span,
  .mat-mdc-list-item span {  // Added MDC support
    font-family: 'TeleGroteskScreenRg';
    font-weight: normal;
    font-size: 18px;
  }

  .mat-divider,
  .mat-mdc-divider {  // Added MDC support
    border-top-color: #d8d8d8;
  }
}


.nav-belt {
  position: relative;
  z-index: 100;
  width: 100%;
  margin: 0px auto;
}

span.rhombus {
  display: none;
}

span.logo {
  display: none;
}

.homeTitle {
  text-align: center;
  font-size: xx-large;
  margin-bottom: 20px;
  margin-top: 50px;
}

.bannerSpacer {
  margin-top: 150px;
}

.home-card {
  padding: 16px !important;
  background: red !important;
  max-width: 950px;

  .mat-card-header,
  .mat-mdc-card-header {  // Added MDC support
  display: block;
}

  h1 {
    font-family: 'TeleGroteskScreenBd', sans-serif;
    text-align: center;
    display: block;
  }

  p {
    padding-top: 10px;
    font-size: 16px;
  }
}


.scroller {

  width: calc(100% + 108px);
  position: relative;
  margin-left: -55px;

  .title {
    margin-left: 11px;
  }

  .mat-tab-header-pagination,
  .mat-mdc-tab-header-pagination {  // Added MDC support
    // Define the space between the pagination arrows and the tab items.
    min-width: 45px;
  }

  .mat-tab-header-pagination-chevron,
  .mat-mdc-tab-header-pagination-chevron {  // Added MDC support
    display: none;
  }

  .mat-tab-header-pagination-before,
  .mat-mdc-tab-header-pagination-before {  // Added MDC support
    background: url('../assets/tmde/img/slider_arrow_left-hov.svg') no-repeat center;
  }

  .mat-tab-header-pagination-disabled:not(.mat-tab-header-pagination-after),
  .mat-mdc-tab-header-pagination-disabled:not(.mat-mdc-tab-header-pagination-after) {  // Added MDC support
    background: url('../assets/tmde/img/slider_arrow_left-dis.svg') no-repeat center;
  }

  .mat-tab-header-pagination-after,
  .mat-mdc-tab-header-pagination-after {  // Added MDC support
    background: url('../assets/tmde/img/slider_arrow_right-hov.svg') no-repeat center;
  }

  .mat-tab-header-pagination-disabled:not(.mat-tab-header-pagination-before),
  .mat-mdc-tab-header-pagination-disabled:not(.mat-mdc-tab-header-pagination-before) {  // Added MDC support
    background: url('../assets/tmde/img/slider_arrow_right-dis.svg') no-repeat center;
  }
}


.searchIcon-title {
  background: url('../assets/tmde/img/search-title.svg') no-repeat center;
}

.searchIcon-artist {
  background: url('../assets/tmde/img/search-artist.svg') no-repeat center;
}

@media only screen and (min-width: 768px) {

  .main-tabs {
    mat-tab-header,
    .mat-mdc-tab-header {  // Added MDC support
      display: none;
    }
  }

  span.logo {
    width: 60px;
    height: 60px;
    left: -61px;
    top: -8px;
  }

  span.rhombus {
    width: 1031px;
    height: 55px;
    left: -1076px;
    top: -7px;

  }

  span.rhombus:before {
    content: ' ';
    border-bottom: 1999px solid #e60000;
    border-right: 1999px inset transparent;
    border-left: none;
    border-top: none;
    left: -933px;
    top: -1990px;
  }

  span.rhombus:after {
    content: ' ';
    border-top: 1999px solid #e60000;
    border-right: 1999px inset transparent;
    left: -937px;
    top: 53px;
  }

  .landing-promo-background {
    background: no-repeat center/cover url("../assets/tmde/img/onboarding-desktop-tmde.jpg") black;
  }

// ERBT-5737: Landing promo styling is currently shared between TMDE and TMAT.
.landing-promo-page {
  app-tone-list-item {
    .mat-list-item,
    .mat-mdc-list-item {  // Added MDC support
      background-color: transparent !important;
      
      .mat-list-item-content,
      .mdc-list-item__content {  // Added MDC support
        height: 113px !important;
      }
    }
  }

  .button-less-list app-tone-list-item:nth-child(2n) .mat-list-item,
  .button-less-list app-tone-list-item:nth-child(2n) .mat-mdc-list-item {  // Added MDC support
    border-right: none !important;
  }

  .mat-list-text {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 13px !important;
  }

  .cover-anchor {
    // Fix for IE where the cover image size exceeds 90x90.
    max-width: 90px;
    max-height: 90px;
  }

  .middle {
    padding-left: 2px;
  }

  .track-title, .artist {
    margin-bottom: 0 !important;
  }
}

.mm-landing-promo {
  app-tone-list-item {
    .mat-list-item,
    .mat-mdc-list-item {  // Added MDC support
      background-color: white !important;
    }
  }

  .mat-list-text {
    border-bottom: transparent !important;
  }

  .track-title, .artist {
    padding-left: 15%;
    color: black;
  }
}

.mm-list {
  padding-bottom: 5%;
}

.mm-landing-promo-image {
  max-width: 926px;
  max-height: 370px;
}

.mm-description {
  margin-bottom: 20px;
}
.mat-toolbar,
.mat-mdc-toolbar {  // Added MDC support for toolbar
  background: mat.get-color-from-palette($accent, 50);

  position: relative;
  z-index: 10;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  min-height: 46px !important;

  .mat-toolbar-row,
  .mat-mdc-toolbar-row {  // Added MDC support for rows
    height: 56px;
  }

  span.title {
    font-family: 'TeleGroteskScreenRg';
    font-size: 21px;
    line-height: 25px;
  }

  span.icon {
    height: 25px;
    width: 35px;
    display: block;
    z-index: 1;
    padding: 4px;
    position: relative;
  }

  .menu-icon {
    background: url('../assets/tmde/img/menu-mobile.svg') no-repeat center;
    background-size: 22px 22px;
    margin-right: 0px;
    position: absolute !important;
    left: 8px;
  }

  .menu-close-icon {
    background: url('../assets/tmde/img/menu-mobile.svg') no-repeat center;
    background-size: 22px 22px;
    margin-right: 0px;
    position: absolute !important;
    left: 8px;
  }

  .search-icon {
    background: url('../assets/tmde/img/search.svg') no-repeat center;
    background-size: 22px 22px;
  }

  .back-button-icon {
    background: url('../assets/tmde/img/back-mobile.svg') no-repeat center;
    background-size: 13px 22px;
  }

  .grid-icon {
    background: url('../assets/tmde/img/view_grid_white.svg') no-repeat center;
    background-size: 22px 22px;
  }

  .list-icon {
    background: url('../assets/tmde/img/view_list_white.svg') no-repeat center;
    background-size: 22px 22px;
  }
}
}

.mini-title-bar-container {
  position: relative;
  width: 100%;
  height: 48px;
  background: $brand-color;

  .logo-holder {
    width: 100%;
    max-width: 945px;
    margin: 0 auto;

    span.logo {
      line-height: 24px;
      padding: 12px;
      display: inline-block;
      -webkit-transition: all 0.25s;
      transition: all 0.25s;
    }

    span.logo:before {
      transition: all 0.25s;
      content: '';
      display: inline-block;
      background: url('../assets/tmde/img/export/telekom_logo_white.svg') no-repeat;
      width: 24px;
      height: 24px;
      margin: 0 auto;
    }
  }
}

mat-card.top-menu,
mat-mdc-card.top-menu {  // Added MDC support
  width: 100%;
  border-radius: 0px;
  background-color: mat.get-color-from-palette($accent, 50) !important;
  padding: 0;
  position: relative;

  mat-card-content,
  .mat-mdc-card-content {  // Added MDC support
    ul {
      max-width: 945px;
      margin: 0 auto;
      padding: 0;
      list-style: none;
      overflow: hidden;
      text-align: justify;

      li {
        position: relative;
        cursor: pointer;
        font-size: 18px;
        display: table-cell;
        padding: 12px 10px;
        white-space: nowrap;
        vertical-align: middle;
        line-height: 38px;

        span.menu-overlay {
          display: none;
          position: absolute;
          top: -6px;
          height: 70px;
          z-index: 100;
          right: 0px;
          left: 0px;
        }

        a {
          display: inline-block;
          font-family: 'TeleGroteskScreenRg';
          font-weight: 400;
          text-decoration: none;
          color: $font-color;
        }
      }

      li.active {
        overflow: visible;
        background-color: rgba(black, 0.2) !important;

        span.menu-overlay {
          display: block;
          z-index: 0;
        }
      }

      li:not(.nohover):hover,
      li.select {
        overflow: visible;

        span.menu-overlay {
          display: block;
          right: -1px;
          left: -1px;
          z-index: 100;
          background-color: rgba(black, 0.2) !important;
        }

        a {
          z-index: 101;
          position: relative;
          color: $font-color;
        }
      }

      li.last {
        border-right: none;
        cursor: pointer;
        width: 100%;

        a mat-icon,
        a .mat-mdc-icon {  // Added MDC support
          height: 19px;
        }
      }

      .register {
        .bt-main {
          padding: 0 8px !important;
          background-color: $brand-color;

          .mat-button-wrapper,
          .mdc-button__label {  // Added MDC support
            color: mat.get-color-from-palette($accent, 50);
          }
        }
      }

      .login {
        padding-left: 1%;
        padding-right: 1%;
        text-align: right;
      }

      .profile {
        a {
          float: right;
          margin-right: 25px;
        }

        a:after {
          content: " ";
          height: 0px;
          width: 0px;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #cccccc;
          position: absolute;
          top: 30px;
          right: 8px;
        }
      }
    }
  }
}

mat-card.sub-menu,
mat-mdc-card.sub-menu {  // Added MDC support
  width: 100%;
  border-radius: 0px;
  box-shadow: none;
  margin-top: -1px;
  background-color: mat.get-color-from-palette($accent, 50) !important;
  padding: 12px;
  position: absolute;
  z-index: 0;

  mat-card-content,
  .mat-mdc-card-content {  // Added MDC support
    ul {
      list-style: none;
      max-width: 945px;
      margin: 0 auto;

      li {
        cursor: pointer;
        position: relative;
        font-size: 18px;
        display: inline-block;
        border-right: 1px solid $border-color;
        padding: 6px 0;
        white-space: nowrap;
        text-align: center;

        a {
          font-weight: normal;
          display: inline-block;
          text-decoration: none;
          color: $font-color;
        }
      }

      li:last-child {
        border-right: 0px solid $border-color;
      }
    }

    ul.library li {
      width: 30%;
    }

    ul.callerExperience li {
      padding-left: 2%;
      padding-right: 2%;
    }

    ul.account li {
      width: 49%;
    }
  }
}

.days-list {
  width: 85%;
  margin: 0 auto;
  clear: both;
  padding-left: 8%;
  padding-top: 4%;

  mat-list-item,
  mat-mdc-list-item {  // Added MDC support
    float: left;
    min-width: 150px;
  
    .mat-slide-toggle-content,
    .mdc-form-field {  // Added MDC equivalent class
      vertical-align: middle;
      line-height: 22px;
    }
  }
  

  p.list-title {
    padding-top: 1%;
    padding-bottom: 1%;
  }
}

.options-block {

  width: 90%;
  margin: 0 auto;

  p {
    margin: 2%;
  }

  p.radio-group-holder {
    width: 85%;
    margin: 0 auto;
    text-align: left;
    .mat-radio-button,
    .mat-mdc-radio-button {  // Added MDC support
      margin-right: 15px;
    }

    margin-bottom: 2%;
  }
}

ul.faq {

  li:first-child {
    border-top: 1px solid $border-color;
  }

  li {
    border-bottom: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-left: 1px solid $border-color;
  }

  h1 {
    color: #333333;
    background-color: mat.get-color-from-palette($accent, 50);
    position: relative;

    span.arrow {
      background: url('../assets/tmde/img/faq-open.svg') no-repeat center;
    }
  }

  .faq-content {
    background-color: mat.get-color-from-palette($accent, 700);
    font-size: 14px;
  }

  li.hidden {
    h1 {
      span.arrow {
        background: url('../assets/tmde/img/faq-closed.svg') no-repeat center;
      }
    }
  }
}

.shop {

  mat-button-toggle,
  .mat-mdc-button-toggle {  // Added MDC support
    margin: 0;
    padding: 0;
  }
  

  .view-toggle {
    span {
      width: 35px;
      height: 35px;
      display: block;
    }

    span.list {
      background: transparent url('../assets/tmde/img/view_list.svg') no-repeat center;
    }

    span.grid {
      background: transparent url('../assets/tmde/img/view_grid.svg') no-repeat center;
    }
  }
}

.mat-dialog-container,
.mdc-dialog__surface {  // Added MDC support
  border-radius: 8px !important;
}


.dialog {
  h1 {
    font-weight: normal;
    font-size: x-large;
    text-align: center;
    color: $font-color;
  }

  .mat-dialog-actions,
  .mdc-dialog__actions {  // Added MDC support
    max-width: 227px !important;
    margin: 0 auto;
    padding-top: 10%;
    display: block;
    width: 100%;
    justify-content: center;
    align-content: center;
    
    button {
      margin-top: 10px;
      width: 100%;
    }
  }

  mat-radio-group,
  .mdc-form-field {  // Added MDC support for radio buttons
    margin: 5% auto 1% auto;
    width: 80%;
    display: block;
    line-height: 30px;
  }
}


.call-party {
  .mat-list-item .mat-list-item-content,  // Non-MDC version
  .mat-mdc-list-item .mdc-list-item__content {  // MDC version
    height: 110px !important;
  }

  .middle {

    p {
      font-weight: normal;
      font-size: large;
    }

    p.igt-title {
      background: url('../assets/tmde/img/message.svg') no-repeat 7px;
    }

    p.igt-title-active {
      background: url('../assets/tmde/img/message-act.svg') no-repeat 7px;
    }

    p.rbt-title {
      background: url('../assets/tmde/img/song.svg') no-repeat 5px;
    }

    p.rbt-title-active {
      background: url('../assets/tmde/img/song-act.svg') no-repeat 5px;
    }

    p.shuffle-title-active {
      background: url('../assets/tmde/img/shuffle-act.svg') no-repeat left;
      background-size: 20px 20px;
    }
  }

  .call-party-name {
    line-height: 22px;
    font-size: large;
  }
}

.box-default {
  background: mat.get-color-from-palette($accent, 700) url('../assets/tmde/img/home-all_callers.svg') no-repeat center;
}

.tone-image-holder {
  app-play-button {
    .playback-button {
      background-repeat: no-repeat;
      background-image: url('../assets/tmde/img/play_2.svg') !important;
      background-size: contain !important;
      z-index: 10;

    }

    .anchor {
      .stop {
        background-repeat: no-repeat;
        background-image: url('../assets/tmde/img/stop_2.svg') !important;
        background-size: contain !important;
      }
      .loading {
        background-repeat: no-repeat;
        background-image: url('../assets/tmde/img/playback_spinner.svg') !important;
        background-size: contain !important;
      }
    }

    .stop {
      background-image: none !important;
    }
  }
}

app-mobile-promo {
  .tile-text {
    background: mat.get-color-from-palette($accent, 50);
    width: calc(100% - 7px);
  }
}

.banner {

  margin-top: 103px;

  .banner-TextArea {
    z-index: 4;
    position: relative;
    width: 100%;
    text-align: left;
    margin: -215px auto;
    max-width: 945px;

    .text-holder {

      float: right;
      text-align: center;
      background-color: transparent;
      border-radius: 3px;
      width: 45%;
      padding: 2%;

      h3 {
        padding: 0;
        margin: 0 0 2% 0;
        text-align: right;
        color: mat.get-color-from-palette($accent, 50);
        font-size: xx-large;
        line-height: 32px;
        font-weight: bold;
        width: 96%;

      }

      button.bt-main {
        position: absolute;
        right: 4%;
        background-color: mat.get-color-from-palette($accent, 50);

        span {
          span {
            color: $brand-color;
          }
        }
      }

    }
  }
}

@media only screen and (max-width: 1125px) {

  .banner {
    margin-top: 55px !important;
  }

  mat-card.top-menu,
  .mat-mdc-card.top-menu {  // Added MDC support
    mat-card-content,
    .mat-mdc-card-content {  // MDC support
      ul {
        li {
          font-size: medium;
          padding: 8px 6px;
        }

        .profile {
          a:after {
            top: 25px;
          }
        }

        .register {
          .bt-main {
            line-height: 28px !important;
            .mat-button-wrapper,
            .mdc-button__wrapper {  // MDC support
              font-size: medium;
            }
          }
        }
      }

      .sharedSearch {

        .searchInput {
          font-size: medium;
        }
      }
    }
  }

  mat-card.sub-menu,
  .mat-mdc-card.sub-menu {  // Added MDC support
    mat-card-content,
    .mat-mdc-card-content {  // MDC support
      ul {
        li {
          font-size: medium;
        }
      }
    }
  }

  .scroller {
    width: auto;
    margin-left: 0;
  }
}


.record-button {

  .outer-path {
    fill: mat.get-color-from-palette($primary, 50);
  }

  .inner-circle {
    stroke: $font-color;
    fill: mat.get-color-from-palette($accent, 50);
  }

  .icon {
    path, rect {
      stroke: $font-color;
    }
  }

}

.recording {

  .inner-circle {
    stroke: mat.get-color-from-palette($primary, 50);
  }

  .icon {
    path, rect {
      stroke: mat.get-color-from-palette($primary, 50);
    }
  }
}

.initial {
  font-family: 'TeleGroteskScreenRg';
  background-color: #02879E;
}

.setting-preview {
  .anchor {
    background: url('../assets/tmde/img/play_3.svg') no-repeat center;
  }

  div.stop {
    background: url('../assets/tmde/img/stop_3.svg') no-repeat center !important;
  }

  div.loading {
    background: url('../assets/tmde/img/playback_spinner.svg') no-repeat center !important;
    background-size: 80px 80px !important;
  }

  div.disabled {
    background: url('../assets/tmde/img/play_3-dis.svg') no-repeat center !important;
  }
}

.floating-confirm-button {
  .confirm-button-container {
    background-color: mat.get-color-from-palette($accent, 700);
  }
}

.tone-list {
  mat-list-item.title,
  .mat-mdc-list-item.title {  // Added MDC support
    border-bottom: 1px solid #dddddd;

    p {
      text-align: left;
      font-weight: bold !important;
    }
  }
}

.homeTiles {

  .mat-grid-tile .mat-figure:hover,
  .mat-mdc-grid-tile .mat-mdc-figure:hover,  // MDC support
  .mat-grid-tile-content:hover,
  .mat-mdc-grid-tile-content:hover {  // MDC support
    border-color: $brand-color;
  }
  

  .tile-content {

    background-color: mat.get-color-from-palette($accent, 50);

    .all-caller {
      background: url("../assets/tmde/img/home-all_callers.svg") no-repeat center;
      background-size: contain;
    }

    .caller-list {
      background: url("../assets/tmde/img/home-individual_callers.svg") no-repeat center;
      background-size: contain;
    }

    .group-list {
      background: url("../assets/tmde/img/home-groups.svg") no-repeat center;
      background-size: contain;
    }

    .record-ugc {
      background: url("../assets/tmde/img/home-record_message.svg") no-repeat center;
      background-size: contain;
    }

    .library {
      background: url("../assets/tmde/img/home-library.svg") no-repeat center;
      background-size: contain;
    }

    .more-setting {
      background: url("../assets/tmde/img/home-settings.svg") no-repeat center;
      background-size: contain;
    }

  }
}

span.date-holder {
  display: block;
  margin: 5% auto;
  position: relative;
  max-width: 200px;
  text-align: center;
}

.md2-datepicker-header {
  background: $brand-color !important;

  span.md2-datepicker-header-date {
    color: mat.get-color-from-palette($accent, 50) !important;
    font-size: large;
  }
}

.md2-datepicker-header-time , .md2-datepicker-header-time span {
  color: mat.get-color-from-palette($accent, 50) !important;
  font-size: large;
}

.md2-datepicker-header-year {
  display: none;
}

.md2-calendar-day.selected, .md2-calendar-day.selected:hover {
  background: $brand-color !important;
}

.md2-datepicker-actions {
  .md2-button {
    color: $font-color !important;
  }
}

.md2-timepicker-icon {
  color: rgba(0, 0, 0, 0.54);
  fill: currentColor;
}

.md2-datepicker-value {
  font-size: medium;
}

@media only screen and (min-width: 768px) {

  .home-page {
    padding-top: 270px;
  }
  
  .home-card {
    // some additional padding here to make sure the
    // home-card does not overlap with the banner
    margin-top: 40px;
  }
  
  .mat-list,
  .mat-mdc-list {  // Added MDC support
    padding-top: 30px !important;
  }
  
  mat-list,
  .mat-mdc-list {  // Added MDC support
    clear: both;
  }
  
  .igt-list-option {
    padding-top: 0px !important;
  }
  
  .tone-list {
    mat-list-item.title,
    .mat-mdc-list-item.title {  // Added MDC support
      margin-left: -2px;
      margin-right: 2px;
      margin-top: -8px;
    }
  }

  app-tone-list-item,
  app-igt-list-item,
  app-artist-tone-list-item,
  app-call-party,
  app-caller,
  app-caller-info,
  app-setting-button {
  
    .mat-list-item,
    .mat-mdc-list-item {  // Added MDC support
      float: left;
      min-width: calc(50% - 2px);
      max-width: calc(50% - 2px);
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      z-index: 1;
      display: flex !important;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      padding: 0 16px;
      position: relative;
      height: inherit;
    }
  
    &:nth-child(2n+1) {
      .mat-list-item,
      .mat-mdc-list-item {  // Added MDC support
        border-left: none;
        z-index: 0;
      }
    }
  }
  
  mat-list-item.list-item-button,
  .mat-mdc-list-item.list-item-button {  // Added MDC support
    width: calc(100% - 3px);
  }
  
  mat-list-item.recording,
  .mat-mdc-list-item.recording {  // Added MDC support
    border-top: 1px solid $border-color;
    min-width: 100%;
    max-width: 100%;
  }
  
  app-setting-button + app-setting-button {
    mat-list-item.list-item-button-setting,
    .mat-mdc-list-item.list-item-button-setting {  // Added MDC support
      border-top: 1px solid $border-color;
      border-left: none;
    }
  }
  
  app-setting-button {
  
    &:nth-child(2n+1) {
      mat-list-item.list-item-button,
      .mat-mdc-list-item.list-item-button {  // Added MDC support
        border-left: 1px solid $border-color !important;
      }
    }
  
    mat-list-item.list-item-button,
    .mat-mdc-list-item.list-item-button {  // Added MDC support
      margin-right: 0px;
    }
  }

  .button-less-list {
    app-igt-list-item, 
    app-tone-list-item {
  
  
      &:nth-child(2n) {
        .mat-list-item,
        .mat-mdc-list-item {  // Added MDC support
          border-right: 1px solid $border-color !important;
          border-left: none;
        }
      }
  
    }
  }

  .homeTiles {
    padding: 0px !important;

    .tile-content {

      .tilesText {
        margin-bottom: 15%;
      }

      .img-holder {
        width: 45%;
        margin: 15% auto;
      }

    }
  }
}

.carousel-indicators {

  li {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 1px 8px 1px 1px;
    text-indent: -999px;
    cursor: pointer;
    background-color: $border-color;
    border: 1px solid mat.get-color-from-palette($primary, 600) !important;
    border-radius: 8px;
  }

  li.active {
    background-color: $brand-color;
  }

}

.carousel-TextArea {

  h3 {
    font-size: xx-large;
    text-align: right;
    line-height: 37px;
    font-weight: bold;
    position: absolute;
    right: 5%;
    width: 40%;
    bottom: 30%;
    color: mat.get-color-from-palette($accent, 50);
  }

  button.bt-main {

    position: absolute;
    right: 5%;
    bottom: 25%;
    background-color: mat.get-color-from-palette($accent, 50);

    span {
      span {
        color: $brand-color;
      }
    }

  }
}

.rbt-activation {
  h1:not(.ad-title) {
    font-family: 'TeleGroteskScreen';
    width: 95%;
    font-size: larger;
  }
  .ad {
    background: no-repeat center/cover url("../assets/tmde/img/register-ad-mobile.jpg") black;
  }

  .ad-title, .description, ul {
    color: mat.get-color-from-palette($accent, 50);
  }
}

.search-result-title {
  font-family: 'TeleGroteskScreenRg';
  font-size: x-large;
  line-height: 130%;
  margin-top: 10px;
  padding: 3%;
  text-align: center;
}

.search-result-content {

  .search-list-item-button {
    text-align: center;
    padding: 3% 0 !important;
    clear: both;

    .small-spinner {
      width: 30px;
      height: 30px;
      background: url('../assets/tmde/img/spinner-small.svg') no-repeat center;
      display: block;
      margin: 0 auto;
    }

  }

  .search-list-item-heading {
    border-bottom: solid 1px #dcdcdc;
    margin-left: -2px;
    margin-right: 1px;
  }

  button {
    font-family: 'TeleGroteskScreenRg';
    font-weight: bold;
    font-size: large;
    margin: 0 auto;
  }

  .tracks-content {
    .mat-grid-tile .mat-figure {
      display: list-item;
      width: 200% !important;
  
      .end {
        margin-right: 5px;
      }
    }
  }
}

.sharedSearch {
  min-width: 295px;
  .searchInput {
    font-family: 'TeleGroteskScreenRg';
    background: #ffffff url('../assets/tmde/img/search-act.svg') no-repeat 4px 6px;
    padding-left: 35px;
  }
}

.tone-list-container {
  .small-spinner {
    background: url('../assets/tmde/img/spinner-small.svg') no-repeat center;
  }
}

.spinner {
  span {
    display: block;
    width: 60px;
    height: 60px;
    background: url('../assets/tmde/img/spinner.svg') no-repeat center;
  }
}

.setting-button-holder {
  width: 100%;
  text-align: center;
  button {
    display: inline;
    margin: 3% 0.2%;
  }
}

.toggle-button {
  .mat-slide-toggle-content,
  .mat-mdc-slide-toggle-content {
    font-family: inherit;
    vertical-align: middle;
    line-height: 20px;
    white-space: nowrap;
  }
}



@media only screen and (max-width: 767.9px) {

  .mat-list,
  .mat-mdc-list {  
    padding-top: 0px !important;
  }
  

  .wrapper {
    width: 100%;
    max-width: none;
    min-width: 0;
  }

  .content {
    margin: 0;
    width: 100%;
  }

  .login-form .stay-login {
    padding: 0 10%;
  }

  p.ugc-list-title {
    border-bottom: solid 1px #dcdcdc;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-top: 16px;
  }

  .homeTitle {
    position: relative;
    z-index: 2;
    text-align: center;
    font-size: x-large;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .sharedSearch {
    margin-top: -1px;
    height: 60px;
    border-color: #ddd;
    background-color: white;
    position: absolute;
    width: 100%;
    z-index: 3;
    box-shadow: 0px 2px 4px #C3C3C3;
    overflow: hidden;

    span.search-icon {
      height: 31px;
      width: 48px;
      display: inline-block;
      z-index: 1;
      padding: 2px;

      top: 10px;
      right: 4%;
      box-shadow: 0px 2px 2px #C3C3C3;
      border: 1px solid #ddd;
      border-radius: 4px;
      position: fixed;

    }

    .searchInput {
      width: 98%;
      height: 39px;
      padding-left: 30px;
      margin-left: 1%;
      margin-right: 1%;
      top: 12px;
      font-size: medium;
      border-radius: 4px;
      box-shadow: inset 0px 2px 2px #DDD;
      border: 1px solid #DDD;
      position: fixed;
      background-position: 4px 9px;
      padding-right: 10px;
    }
  }

  // ERBT-5737: Landing promo styling is currently shared for TMO.
  .landing-promo-page {
    p.description {
      padding-bottom: 5%;
    }
  }

  .landing-promo-background,
  .mm-promo-background {
    .mat-toolbar .title,
    .mat-mdc-toolbar .title {  
      margin-left: 0px !important;
    }
  }
}

@media only screen and (max-width: 375px) {
  .sharedSearch {
    .searchInput {
      width: 98%;
    }
  }

}

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px;
  }

  .carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px;
  }

  .rbt-activation {
    .ad {
      background: no-repeat center/cover url("../assets/tmde/img/register-ad-desktop.jpg") black;
    }
  }

}

@media only screen and (max-width: 500px) {

  .rbt-activation {
    .lead {

      h1, h2, p {
        line-height: 100%;
      }

      p.description {
        text-align: left;
        font-size: 90%;
        margin-left: 0.5em !important;
        margin-right: 0.5em !important;
      }
    }

    .ad-title {
      font-weight: bold;
      font-size: 100%;
    }

  }

}

@media only screen and (min-device-width: 568px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .img-properties {
    background-position-y: 35% !important;
    background-size: 120px 120px;
  }
}

// Iphone 6 && Galaxy S5
@media only screen and (min-device-width: 360px) and (max-device-width: 400px) and (-webkit-min-device-pixel-ratio: 2) {
  .img-properties {
    background-position-y: 15px !important;
    background-size: 50px 50px;

  }
}

// Iphone 5
@media only screen and (min-device-width: 320px) and (max-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .img-properties {
    background-position-y: 15px !important;
    background-size: 40px 40px;

  }
}

@media only screen and (max-width: 369px) {
  .homeTiles .tile-content .tilesText  {
    font-size: 0.78em;
  }
}

@media only screen and (max-width: 355px) {
  .homeTiles .tile-content .tilesText  {
    font-size: 0.7em;
  }
}

@media only screen and (max-width: 330px) {
  .homeTiles .tile-content .tilesText  {
    font-size: 10px;
  }
}

.logoutButton {
  color: $font-color;
  font-size: 16px;
}

.toogleMenu {
  top: 83px;
}

.footer {
  background-color: $footer-color;
  border: solid $footer-color;
  margin: 0;
  height: 100%;

  .footerBar {

    width: 100%;

    .footerText {
      color: mat.get-color-from-palette($accent, 50);
      max-width: 945px;
      padding: 25px 5%;
      margin: 0 auto;

      span {
        padding-right: 2%;

        a {
          cursor: pointer;
          display: inline-block;
          text-decoration: none;
          color: mat.get-color-from-palette($accent, 50);
        }
        a:hover {
          text-decoration: underline;
        }
      }

      .copyright {
        padding-right: 0px;
        color: mat.get-color-from-palette($accent, 50);
        font-weight: normal;
        font-size: 12px;
        float: right;
      }
    }
  }
}

.error {
  font-family: 'TeleGroteskScreenLt';
  position: relative;
  width: 98%;
  display: block;
  margin: 0 auto;

  .error-title {
    padding: 4%;

    .error-code-message {
      font-size: 120%;
      padding-top: 2%;
      margin-bottom: 2%;
    }
  }

  .error-button {
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
    display: block;

    button {
      margin-top: 4%;
      margin-right: 3px;
      display: inline-block;
    }
  }

}

// Iphone 4
@media only screen and (max-device-width: 320px) and (max-device-height: 480px) {

  // [General] The original padding produces two strange blocks at the top
  .mat-toolbar {
    padding: 0px !important;
  }

  // [Shop page] Fixes the layout of the types of view
  span.icon.list-icon, span.icon.grid-icon {
    position: absolute !important;
    right: 15% !important;
    top: 0px !important;
  }

  // [General] Aligns to the center the title avoiding overlays
  span.title {
    float: left !important;
    width: 70% !important;
    margin-left: 15% !important;
    margin-top: 2%;
  }

  // [General] Fixes the overlay of the logo
  span.logo {
    float: left !important;
    top: 0px !important;
    left: 0px !important;
  }

  // [General] Fixes the overlay of the search icon
  span.search-icon {
    margin-top: 2%;
  }

  // [General] Fixes the overlay of the menu icon
  span.icon.menu-icon {
    float: left !important;
  }

  // [Details page] Aligns to the left the back arrow
  span.icon.back-button-icon {
    position: absolute !important;
    margin-top: 2%;
  }

  // [Detail page] Adds a margin between the title and the cover
  div.mat-toolbar-layout {
    margin-bottom: 10px !important;
  }
}

// These values are the default mat-raised-button used by Angular Material 4
app-tone-list-item, .tile-button {
  .mat-raised-button,
  .mat-mdc-raised-button { // Added MDC version
    min-width: 88px !important;
    line-height: 36px !important;
    border-radius: 2px !important;
    font-family: inherit !important;
  }
}

.register .mat-raised-button,
.register .mat-mdc-raised-button { // Added MDC version
  line-height: 34px !important;
}

.mat-radio-button,
.mat-mdc-radio-button {  // Added MDC version
  font-family: inherit !important;
}

// *** Custom styling for outlined input fields ***

/* Removing input background colour for Chrome autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $body-color inset !important;
  -webkit-text-fill-color: $font-color !important;
  background-color: $body-color !important;
  color: $font-color !important;
}

// mat-form-field-outline focused color
.mat-form-field-appearance-outline.mat-focused:not(.mat-form-field-invalid),
.mat-mdc-form-field-appearance-outline.mat-focused:not(.mat-mdc-form-field-invalid) { // Added MDC version
  .mat-form-field-outline-start, .mat-form-field-outline-gap, .mat-form-field-outline-end,
  .mat-mdc-form-field-outline-start, .mat-mdc-form-field-outline-gap, .mat-mdc-form-field-outline-end { // Added MDC version
    color: $brand-color !important;
  }
}

// mat-form-field-outline focused error color
.mat-form-field-appearance-outline.mat-form-field-invalid,
.mat-mdc-form-field-appearance-outline.mat-mdc-form-field-invalid { // Added MDC version
  .mat-form-field-outline-start, .mat-form-field-outline-gap, .mat-form-field-outline-end,
  .mat-mdc-form-field-outline-start, .mat-mdc-form-field-outline-gap, .mat-mdc-form-field-outline-end { // Added MDC version
    color: #f44336 !important;
  }
}

// Magenta Moments
.mm-title {
  line-height: normal;
}

.mm-checkbox {
  display: block !important;
  padding-left: 5%;
  font-size: 14px;
  margin-bottom: 15px;
  // make sure our text line breaks if it's too long and that the checkbox aligns with the first line of the text.
  // See https://github.com/angular/components/issues/8416
  padding-right: 5%;
  label.mat-checkbox-layout,
  label.mat-mdc-checkbox-layout {  // Added MDC version
    white-space: normal;
  }
  span.mat-checkbox-inner-container,
  span.mat-mdc-checkbox-inner-container {  // Added MDC version
    margin-top: 4px;
  }
}

.mm-optin-card {
  text-align: left;
  margin-top: 12px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 12px;
}

.mm-optin-card-heading {
  font-size: 16px;
  padding-bottom: 4px;
}

.mm-optin-card-bullet {
  padding-left: 10%;
}

.mm-tone-list {
  padding-bottom: 5%;
}

.mm-list {
  overflow: hidden;
}

.ivr-section {
  // ERBT-6545: Disable IVR section
  display: none !important;
}

