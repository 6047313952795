/*
 * Classes and mixins that are not specific to any page and can be used to build specific layouts.
 */


// Helpers to apply margins, similar to how Bootstrap 4 does it

$space: 1.5em;

.mx-1 {
    margin-left: $space * 0.3 !important;
    margin-right: $space * 0.3 !important;
}

.mx-2 {
    margin-left: $space * 0.6 !important;
    margin-right: $space * 0.6 !important;
}

.mx-3 {
    margin-right: $space !important;
    margin-left: $space !important;
}

.mx-4 {
    margin-left: $space * 1.3 !important;
    margin-right: $space * 1.3 !important;
}

.mx-5 {
    margin-left: $space * 1.6 !important;
    margin-right: $space * 1.6 !important;
}

.mx-6 {
    margin-left: $space * 2 !important;
    margin-right: $space * 2 !important;
}



.ml-1 {
    margin-left: $space * 0.3 !important;
}

.ml-2 {
    margin-left: $space * 0.6 !important;
    
}

.ml-3 {
    margin-left: $space !important;
}

.ml-4 {
    margin-left: $space * 1.3 !important;
}

.ml-5 {
    margin-left: $space * 1.6 !important;
    
}

.ml-6 {
    margin-left: $space * 2 !important;
}

.ml-7 {
    margin-left: $space * 2.3 !important;
}

.ml-8 {
    margin-left: $space * 2.6 !important;
}



.my-1 {
    margin-top: $space * 0.3 !important;
    margin-bottom: $space * 0.3 !important;
}

.my-2 {
    margin-top: $space * 0.6 !important;
    margin-bottom: $space * 0.6 !important;
}

.my-3 {
    margin-top: $space !important;
    margin-bottom: $space !important;
}

.my-4 {
    margin-top: $space * 1.3 !important;
    margin-bottom: $space * 1.3 !important;
}

.my-5 {
    margin-top: $space * 1.6 !important;
    margin-bottom: $space * 1.6 !important;
}

.my-6 {
    margin-top: $space * 2 !important;
    margin-bottom: $space * 2 !important;
}

.my-7 {
    margin-top: $space * 2.3 !important;
    margin-bottom: $space * 2.3 !important;
}

.my-8 {
    margin-top: $space * 2.6 !important;
    margin-bottom: $space * 2.6 !important;
}


.mb-1 {
    margin-bottom: $space * 0.3 !important;
}

.mb-2 {
    margin-bottom: $space * 0.6 !important;
}

.mb-3 {
    margin-bottom: $space !important;
}

.mb-4 {
    margin-bottom: $space * 1.3 !important;
}

.mb-5 {
    margin-bottom: $space * 1.6 !important;
}

.mb-6 {
    margin-bottom: $space * 2 !important;
}

.mt-1 {
    margin-top: $space * 0.3 !important;
}

.mt-6 {
    margin-top: $space * 2 !important;
}
