@mixin list-item($border-color) {
    border-bottom: 1px solid $border-color;
    padding: 4% 3% 4% 3%;
    display: block;
}

@mixin fill-icon-button($iconSize) {
  // TODO: Remove important
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  min-height: 0 !important;
  min-width: 0 !important;
  top: 0;
  left: 0;
  line-height: 1 !important;
  padding: 0 !important;
  background-size: $iconSize $iconSize !important;
  background-color: transparent;
  background-repeat:  no-repeat !important;
  background-position: center !important;
}


@mixin title {
  font-size: 130%;
  width: 100%;
  display: block;
  margin-bottom: 2%;
}



@mixin rotate($angle) {

  /* Safari */
  -webkit-transform: rotate($angle);

  /* Firefox */
  -moz-transform: rotate($angle);

  /* IE */
  -ms-transform: rotate($angle);

  /* Opera */
  -o-transform: rotate($angle);

}
