html {
  --mat-form-field-container-height: 45px !important;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  border: 0;
}

body, p, h1, div, ul, span {
  margin: 0;
  padding: 0;
}

.clear {
  clear: both;
}

span.tab-border {
  display: none;
}

mat-toolbar-row {
  justify-content: space-between;
}


  // ERBT-5532: Fix for menu items being hidden on iPhone (iOS 14), related
  // to a CSS fix for supporting 100vh in Webkit (Safari).
  .mat-sidenav-ios {
    height: -webkit-fill-available !important;  /* Mozilla-based browsers will ignore this. */
 }

mat-sidenav-container {
  display: inline;
  min-height: 100vh;
}

mat-sidenav {
  width: auto;
  min-width: 220px !important;
  min-height: 390px;
  font-weight: bold;

  mat-list-item {
    position: relative;
    font-size: 18px;
    line-height: 22px;
  }
}

// Fix for tab label text getting cut on mobile
.mat-tab-label {
  flex: 0 0 auto !important;
}

// Fix for tab change in Edge (See: https://github.com/angular/material2/issues/4562)
.mat-tab-body-content{
  overflow: unset !important;
}

mat-list-item {
  position: relative;
}

h1.main-heading {
  font-weight: normal;
  font-size: 28px;
  text-align: center;
  line-height: 34px;
}

p.sub-heading {
  padding: 5%;
  text-align: center;
}
span.toolbar-icons {
  position: absolute;
  right: 10px;
  display: block;
}

.wrapper {
  max-width: 945px;
  min-width: 840px;
  padding-bottom: 10%;
  margin: 0 auto;
  min-height: 100vh;
  overflow: hidden;
}

.content {
  height: 100%;
  max-width: 945px;
  margin-top: 4%;
  width: 98%;
  margin-right: 1%;
  margin-left: 1%;
}

app-title-bar {

  mat-toolbar {

    .title {
      text-align: center;
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      margin-left: 52px;
      margin-right: 5px;
    }

    .back-button-icon + .title {
      margin-left: 0px;
    }
  }
}

.title {
  text-align: center;
  font-size: 15px;
  width: 100%;
}

h4.heading {
  text-align: center;
  font-size: 175%;
}

.small-container {
  padding-top: 2%;
  margin: 0 auto;
  height: 100%;
}

.login-form {
  padding: 0;

  .stay-login {

    display: block;
    margin-top: 10%;

    p {
      display: block;
      padding-top: 5%;
      padding-bottom: 5%;
    }
  }
}

.comfort-login {

  .comfort-login-information {
    text-align: center;
    padding: 5%;
    text-decoration: underline;
  }

  .bt-main {
    display: block;
    margin: auto;
    min-width: 35%;
    margin-bottom: 5%;
  }
}

mat-form-field {
  width: 100%;
  align-content: center;
}

.login-form  {
  mat-form-field {
    margin: 5% auto;
    width: 84%;
    display: block;
  }
}

h1.tone-title, h1.tone-list-title {
  line-height: 25px;
  text-align: left;
}

.tone-free-text {
  font-weight: 700;
}

span.center {
  text-align: center;
  display: inline-block;
  margin-top: 5%;
  margin-bottom: 5%;
  width: 100%;
}

div.center, img.center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.fill {
  width: 100%;
  height: 100%;
}

.square {
  width: 100%;
}

h1.faq-title {
  font-weight: normal;
  padding: 2%;
  line-height: 0.95; // Small value requested by Marcela (ERBT-5273)
}

p.faq-content {
  padding: 2%;
}

.mat-autocomplete-panel.mat-autocomplete-panel-below {
    top: 1px !important;
}

ul.faq {
  max-width: 940px;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

  li {
    padding: 0;
    margin: 0;
    overflow: hidden;

    h1 {
      cursor: pointer;
      width: 97%;
      padding: 2%;
      font-size: 18px;
      line-height: 35px;
      font-weight: normal;
      margin-left: -1px;
      z-index:1;

      -webkit-box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.3);
      box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.3);

      span.question-title {
        display: block;
        width: 90%;
      }

      span.arrow {
        position: absolute;
        height: 20px;
        width: 20px;
        right: 15px;
        top: 40%;
      }
    }

    .faq-content {
      width: 92%;
      position: relative;
      -webkit-transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
      -moz-transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
      -ms-transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
      transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
      height: auto;

      padding: 2% 4% 4%;
      overflow-y: hidden;
    }

    .faq-content ul {
      list-style: inside disc;
      text-indent: -1.28571429em;
      margin-left: 1.28571429em;
    }
    .faq-content li {
      border:none;
      overflow: visible;
    }
  }

  li.hidden {
    .faq-content {
      overflow: hidden;
      height: 0px;
      padding: 0;
    }
  }
}

.faq-back-button-holder {
  text-align: center;
  width: 100%;
  margin-top: 2%;
}


.border-top {
  border-top: 1px solid #dddddd;
}

.shop {
  .view-toggle {
    box-shadow: none;
  }
}

app-tone-list-item, app-igt-list-item {

  .tone-item-container {
    overflow: hidden;
  }

  .track-title, .artist {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .track-title {
    font-weight: bold;
  }

  .artist, .sms {
    font-weight: normal;
  }

  .price {
    font-weight: bold;
    text-align: right;
  }

  .middle {
    height: 60px;
  }

  div.igt {
    max-width: calc( 100% - 60px) !important;
  }

  div.library {
    max-width: calc( 100% - 155px) !important;
  }

  .middle:not(.library):not(.igt) {
    max-width: calc( 100% - 180px) !important;
  }
}

.bt-close {
  min-width: 35px !important;
  height: 35px;
}

.app-igt-list-item div{
  .track-title, .artist {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .track-title {
    font-weight: bold;
  }

  .artist, .sms {
    font-weight: normal;
  }

  .price {
    font-weight: bold;
    text-align: right;
  }

  .middle {
    height: 60px;
  }

  .bt-close {
    min-width: 35px;
    height: 35px;
    background-size: 20px 20px !important;
    background-color: transparent;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-image: url('../assets/vfde/img/close.svg') !important;
  }

}

app-artist-tone-list-item  {
      cursor: pointer;
      .artist {
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
      }

      .middle {
        height: 60px;
      }

      .bt-close {
        min-width: 35px;
        height: 35px;
        background-size: 20px 20px !important;
        background-color: transparent;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-image: url('../assets/vfde/img/close.svg') !important;
      }
}

app-tone-grid-item {
  width: 100%;
  height: 100%;
}

.progress {
  h1 {
    min-width: 300px;
    text-align: left;
  }
  mat-progress-bar {
    width: 100%;
    margin: 0 auto;
  }
}

.spinner {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.igt-list-item {
 button {
   box-shadow: none;
   background: transparent;
 }

 .mat-button-focus-overlay {
     background-color: transparent !important;
 }

 .mat-button {
   min-width: 1%;
 }
}

.shared-search {
  max-width: 90%;
  height: 22px;
  background-color: white;

  mat-form-field {
    background-color: white;
    height: 0px;
  }

  mat-placeholder {
    padding-left: 30px;
  }

  input {
      background-color: white;
      height: 37px;
  }

  .mat-input-infix {
    background-color: white;
  }
}


// Needed to provide an anchor point for absolute overlay children
.cover-anchor {
  position: relative;

  // Fix to adjust to height of children
  // Otherwise it adjusts to height of parent
  display: flex;
  min-height: 60px;
  min-width: 60px;
  max-height: 60px;
  max-width: 60px;

  span {
    line-height: 0px;
  }
}

.anchor {
  position: relative;
  min-height: 60px;
  min-width: 60px;

  span.initial {
    line-height: 60px;
    position: absolute;
  }
}

.initial {
  font-size: 40px;
  height: 100%;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #FFFFFF;
}

.playback-button {
  @include fill-icon-button(29px);
}

.account-tab-content {

  padding: 5% 5% 0 5%;
  text-align: center;
  align-content: center;

  button {
    margin-top: 5%;
  }

  .toggle-button  {
    text-align: center;
    align-content: center;
    .mat-slide-toggle {
      display: inline-block;
    }
  }

  p.sub-heading {
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .account-option-heading {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0;
  }

}

.account-content-packages {
  border: 1px solid #000000;
  border-radius: 10px;
  margin-bottom: 5%;
  .account-content-package-name {
    padding: 10px;
    font-weight: 700;
    border-bottom: 1px solid #000000;
    border-radius: 10px;
  }
}

.account-information {
  display: table;
  width: 100%;
  div {
    display: table-row;
    div {
      display: table-cell;
      padding: 2%;
      min-width: 150px;
      width: 0;
      .value {
        font-weight: 700;
      }
    }
  }
}

.account-information-column {
  display: inline-block;
  width: 33%;
  vertical-align: middle;

  .value {
    font-weight: 700;
  }

  div {
    padding: 5%;
  }

  .bt-main {
    margin-top: 0%;
  }
}

.ugc-container {
  padding-top: 5%;
  padding-bottom: 5%;
  height: 75vh;
  text-align: center;
  position: relative;

  p {
    font-size: 14px;
  }

  p.ugc-title {
    margin-bottom: 5%;
  }

  .bt-main {
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translateX(-50%);
  }
}

.ugc-upload {
  padding-top: 2%;
  height: 100vh;
}

.middle {
  justify-content: center;
}

app-igt-list-item {

  mat-list-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .artist {
    font-weight: bold;
  }

  .middle {
    height: 60px;
    justify-content: center;
  }

}

mat-list-item.list-item-button {

  height: 77px !important;
  padding-top: 15px;
  cursor: pointer;

  span.icon {
    display: block;
    height: 60px;
    min-width: 60px;
    background-size: 32px 32px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
  }

  .middle {
    height: 60px;
  }

  p.title {
    text-align: left;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .end  {
    span.icon {
      background-position: 20px 14px;
    }
  }

}

mat-list-item.list-item-button-setting {
  border-top: 1px solid #dddddd;
}

p.ugc-list-title {
  font-weight: bold;
  width: 100%;
  text-align: left;
  padding-left: 20px;
  padding-bottom: 8px;
  padding-top: 8px;

  & + .mat-list {
    padding-top: 0px;
  }
}

app-tone-list-item,
app-artist-tone-list-item,
app-call-party {
  .mat-list-base, .mat-list-item, .mat-mdc-list-item, .mat-mdc-list-base {
    height: 100px !important;
  }
}

.call-party {
  .mat-list-item-content,
  .mat-mdc-list-item-content {
    height: 110px !important;
  }

  span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space:nowrap;

  }

  .middle {
    height: 60px;
    max-width: calc( 100% - 120px) !important;

    p {
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      padding-left: 25px;
      min-height: 18px;
    }

    p.igt-title {
      margin-bottom: 8px !important;
    }

    p.phone-number {
      font-weight: normal;
    }

    .bt-delete {
      z-index: 20;
      position: relative;
    }
  }

  .call-party-name {
    padding: 5px 0 5px 0;
    font-size: 15px;
    line-height: 17px;
    span.name {
      font-weight: bold;
    }
  }
}

app-caller-info {
  .mat-list-base, .mat-list-item, .mat-list-item-content {
    height: 80px !important;
  }
}

.caller {
  border-bottom: 1px solid #dddddd;

  .mat-list-item-content,
  .mat-mdc-list-item-content {
    height: 80px !important;
  }

  .middle {
    height: 60px;

    p {
      font-size: 18px;
      font-weight: bold;
      line-height: 20px;
      min-height: 18px;
    }

    p.phone-number {
      font-weight: normal;
    }
  }


  span.initial {
    height: 60px;
    display: block;
    width: 60px;
    line-height: 60px;

  }


  .bt-delete {
    z-index: 20;
    position: relative;
  }

 mat-list-item {
    border-bottom: 1px solid #dddddd;
  }

  .end {
    height: 60px;
  }

}

.box-setting {
  margin: 5% auto;
  width: 120px;
  height: 120px;
  line-height: 120px;
}

.setting-preview {
  height: 100px;
  width: 100px;
  padding: 10px;
  display: block;
  margin: 30px auto;
  clear: both;

  .anchor {
    height: 100%;
  }

  .playback-button {
    border-radius: 50% !important;
    .mat-button-ripple {
      border-radius: inherit;
    }
  }
}


.tone-page {

  h2 {
    font-weight: 100;
  }

  .buy-button {
    width: 100%;
    max-width: 150px;

    font-size: larger;
    font-weight: bolder;
    font-family: inherit;
    // These values are the default mat-raised-button used by Angular Material 4
    min-width: 88px !important;
    line-height: 36px !important;
    border-radius: 2px !important;
  }
}

.confirm-button-container {

  position: relative;
  width: 100%;

  // The margin of 10% comes from the wrapper
  // In this case we removed and added a padding-bottom of 15px
  margin-bottom: -10%;

  padding-top: 15px;
  padding-bottom: 15px;
  pointer-events: auto;
  line-height: 100px;
  z-index: 1000;
  text-align: center;

  button {
    display: block;
    margin: 0 auto;
  }
}

.library-setting {

  text-align: center;

  button {
    display: block;
    margin: 0 auto;
    z-index: 1000;
  }

  .confirm-button-top {
    display: none;

    p {
      text-align: center;
      width: 100%;
      padding:0;
    }

    button {
      margin: 2% auto;
    }
  }

}

button.setting-grey-button {
  margin: 5% auto;
  display: block;
}


app-setting-button + app-setting-button {
  mat-list-item.list-item-button-setting {
    border-top: none;
  }
}

app-setting-button, app-call-party, .tone-selection {
  cursor: pointer;
}

.days-list {
  width: 85%;
  margin: 0 auto;
  padding-left: 3%;
  padding-top: 2%;

  p.list-title {
    padding-top: 1%;
    padding-bottom: 1%;
  }
  
  .mat-slide-toggle-label {
    font-size: 14px !important;
  }
}

.bt-center {
  margin: 5% auto;
}

.time-select {

  .list-title {
    padding: 2%;
  }

  .time-picker-holder {

    text-align: center;
    padding: 2%;
    width:98%;

    & > span {
      display: inline-block;
      margin-right: 40px;
    }

    app-md2-datepicker {

      position: relative;
      display: inline-block;

      .md2-datepicker-trigger {
        padding: 18px 0 4px 2px;
      }

      .md2-datepicker-input {
        width: 80px !important;
        min-width: 80px !important;
      }

      .md2-datepicker-arrow {
        display: none;
      }

      .md2-datepicker-value {
        // ERBT-5591: "fix" to disable browser's auto zoom in when the 
        // input field font size is less than 16px.
        font-size: 16px;
        font-family: inherit;
        font-weight: bold;
      }

      .md2-datepicker-clear {
        display: none;
      }
    }
  }

}

.homeTiles {

  padding: 15px;

  .tilesImage {
    border-radius: 10px;
    align-self: flex-start;
  }

  .mat-grid-tile .mat-figure,
  .mat-grid-tile .mat-grid-tile-content {
    border: 1px solid #dcdcdc;
    height: auto;
    align-items: flex-start;
    cursor: pointer;
    margin-right: 1px;
    border-radius: 4px;
    text-align: center;
    overflow: hidden;
  }

  .mat-grid-tile .mat-figure:hover {
    border-color: #680f6b;
  }

  .tile-content {
    z-index: 2;
    width: 100%;
    height: 100%;

    position: relative;

    .tilesText {
      margin-bottom: 4%;
      bottom: 0;
      position: absolute;
      text-align: center;
      display: block;
      width: 90%;
      padding: 0 5%;
      font-size: 13px;
      line-height: 12px;
    }

    .img-holder {

      width: 60%;
      margin: 3% auto;

      img {
        visibility: hidden;
        width: 100%;
      }

    }
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-20 {
  margin-top: 20px;
}


.img-responsive,
.thumbnail > img,
.thumbnail a > img,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  max-width: 100%;
  height: auto;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  max-height: 340px;
  overflow: hidden;
}


.carousel-inner > .active {
  display: block;
}

.carousel-inner > .active {
  left: 0;
}

.carousel-inner > .active.left {
  left: -100%;
}

.carousel-inner > .active.right {
  left: 100%;
}

.carousel-indicators {
  position: absolute;
  bottom: -50px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;

  li {
    margin: 1px 8px 1px 1px;
  }
}

.carousel-caption {
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: black;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
}

.carousel-caption .btn {
  text-shadow: none;
}


.tile-text {

  position: absolute;
  bottom: 0px;
  width: calc(100% - 8px);
  padding: 4px;

  p {
    text-align: center;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 auto;
    display: -webkit-box;
    line-clamp:2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

}

.carousel-box {

    max-width: 945px;
    min-width: 840px;
    max-height: 340px;
    top: 0px;

  .carousel-TextArea {
    top: 0;
    position: absolute;
    width: 100%;
    height: 87.3%;
    z-index: 100;
    border-radius: 10px;
  }

     .align-r {
       float: right;
     }

  app-promo {
    .slide-promo {
      height: 340px;

    }

    img {
      width: calc(100% - 2px);
      height: 100%;
      max-height: 340px;
      border-radius: 10px;
      border: 1px solid #dcdcdc;
    }
  }
}

  .carousel.fade {
				opacity: 1;

	}
	.carousel.fade .item {

			transition: opacity ease-out .7s;
			left: 0;
			opacity: 0; /* hide all slides */
			top: 0;
			position: absolute;
			width: 100%;
			display: block;

    .carousel-TextArea {
      display: none;
    }
	}
	.carousel.fade .item:first-child {
			top: auto;
			opacity: 1; /* show first slide */
			position: relative;
	}
	.carousel.fade .item.active {
		opacity: 1;

    .carousel-TextArea {
      display: block;
    }
	}

.bannerSpacer {
  height: 150px;
}

.banner {
  height: 340px;
  max-height: 340px;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 2;
  width: 100%;

  .image-holder {
    z-index: 2;
    height:  340px;
    width: 100%;
    display: block;
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .banner-TextArea {
    z-index: 4;
    position: relative;
    width: 100%;
    text-align: left;
    margin: -215px auto;
    max-width: 945px;

    .text-holder {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 3px;
      width: 45%;
      padding-left: 5%;
      padding-top: 2%;
      padding-bottom: 3%;


      h3 {
        padding: 0;
        margin: 0 0 2% 0;
        font-size: 200%;
        text-align: left;
        line-height: 32px;
        font-weight: normal;
        width: 96%;

      }

    }
  }

}

.home-card {
  margin-bottom: 5%;
  background-color: red !important;
  color: white !important;

  h1 {
    font-family: 'VodafoneLt';
    font-size: x-large;
  }

  p {
    font-size: 115%;
  }
}

app-mobile-promo {

  z-index: 2;
  width: 100%;
  height: 100%;
  position: relative;


  .promo-image-holder {
    width: 100%;
    height: 100%;

    .fillImageTiles {
      width: 100%;
      height:100%;
      top: 0px;
      position: inherit;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

}

.tone-title, .tone-list-title {
  text-align: center;
  @include title();
}

.shop-scroller > mat-tab-group {
    .mat-tab-label {
      // Fixes issue where the pagination arrows were not displayed
      // when the total width of the .scroller exceeds 100%.
      min-width: 215px !important;
    }
}

.scroller {

  .title {
    @include title();
    text-align: left;
    padding-left: 45px;
  }

  .mat-tab-header {
    border-bottom: none;
  }

  .mat-tab-body-wrapper {
    display: none;
  }

  .mat-ink-bar {
    display: none;
  }

  .mat-tab-header-pagination  {
    .mat-tab-header-pagination-chevron {
      display: none !important;
    }
  }

  .mat-tab-label,
  .mat-mdc-tab {
    max-height: none !important;
    max-width: none !important;
    min-height: initial !important;
    min-width: initial !important;

    height: auto !important;
    width: initial !important;
    line-height: 0;
    padding: 0 !important;
    margin: 0 3px;
    opacity: 1 !important;
    flex: 1 !important;
    .mdc-tab-indicator {
      display:none;
    }
    // IE11 Fix only for tone scroller
    .tone-scroll-img {
      width: 135px;
    }

    // The default line-height adds unwanted pixels
    .fallback-img {
      line-height: 0;
      padding: 0px;
      margin: 4px;
      min-width: 135px;
      min-height: 135px;

      img {
        border-radius: 4px;
        border: 1px solid rgba(0,0,0,0.2);
      }
    }

    .details {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      overflow: hidden;
      height: 0;
      right: 1px;
      left: 1px;
      bottom: 1px;
      border-radius: 0px 0px 4px 4px;
      font-weight: bold;
      background: rgba(#fff, 1);
      transition: height cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
      width: 100%;
      .playback-button {
        left: -5px;
        top: -13px;
      }
    }

    .details-overlay {
      position: absolute;
      width: 100%;
      height: 100%;

      bottom: 0px;
      left: 0;

      opacity: 0;
      border-radius: 4px;
      background: rgba(#000, 0.35);
      transition: opacity 0.3s;
    }
  }

  .mat-tab-label:hover, .mat-tab-label:focus,
  .mat-mdc-tab:hover , .mat-mdc-tab:focus {
    .details {
      height: 36px;
    }

    .details-overlay:hover {
      opacity: 1;
    }
  }
}

.mat-simple-snackbar {
  text-align: center;
  justify-content: center !important;
  width: 100%;
}

.rbt-activation {

  .title-bar-header-footer {
    display: none;
  }

  p.legal {
    font-size: small;
    text-align: left;
    width: 100%;
  }

  .lead {
    h1, h2, p {
      text-align: center;
      line-height: 120%;
      max-width: 500px;
    }

    p.description {
      text-align: left;
      font-size: 1.2em;
      line-height: 1.2em;
    }

    strong {
      text-align: center;
      width: 100%;
      display: block;
    }

    ul {
      padding-left: 5%;
    }

  }


  .ad {
    height: 100vh;
  }

  .ad-title {
    font-weight: bold;
    font-size: 1.45em;
    line-height: 1.2em;
    margin-bottom: 4%;
  }

  .choice h1, .register h1 {
    font-weight: 100;
  }

  .register {
    h1, h2, p {
      text-align: center;
      line-height: 100%;
    }

    app-legal-text {
      .lead {
        p {
          font-size: small;
          line-height: 120%;
        }
      }
    }
  }
}

.landing-promo-page {

  .landing-promo-image {
    width: 100vw;
    height: 40vw;
  }

  p.title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    padding: 5% 0%;
  }

  p.description {
    font-size: 16px;
    text-align: center;
    padding: 0% 5%;
  }

  p.skip {
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    width: max-content;
    margin: auto;
  }
}

.landing-promo-holder {
  .tone-image-holder {
    width: 50%;
  }

  .landing-promo-text {
    text-align: center;
    line-height: 100%;
  }

  .landing-promo-title {
    font-size: 20px;
  }

  h2 {
    margin-bottom: 0px;
    text-align: center;
  }
}

.landing-promo-legal {
  p.legal {
    padding-right: 5%;
    padding-left: 5%;
    padding-bottom: 5%;
    font-size: 12px;
    text-align: center;
    line-height: 100%;
  }
}

.retry-subscription-information {
  padding-top: 5%;
  p.legal {
    padding-top: 5%;
  }
}

.fallback-img {
  box-shadow: inset 0 0 20px 2px rgba(0, 0, 0, 0.1);
  position: relative;

  &::after, &::before {
    margin: 2.5%;
    box-sizing: border-box;
    content: '';
    position: absolute;
    width: 95%;
    height: 95%;
    top: 0;
    left: 0;
    z-index: -1;

    // Too performance heavy
    //box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.3);
    // clip-path: circle(10% at 0 0);
    //animation: clip 4s linear infinite;
  }

  &::before {
    // animation-delay: -2s;
  }
}

.tone-list-container {
  .small-spinner {
    width: 30px;
    height: 30px;
    display: block;
    margin: 2% auto;
  }
}

app-title-bar, app-spinner {

  display: block;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
}

.purchase-option-block {
  img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
}

@keyframes clip {
  0%, 100% {
    clip-path: circle(10% at 0% 0%);
  }
  12.5% {
    clip-path: circle(20% at 0% 50%);
  }
  25% {
    clip-path: circle(30% at 0% 100%);
  }
  37.5% {
    clip-path: circle(5% at 50% 100%);
  }
  50% {
    clip-path: circle(30% at 100% 100%);
  }
  62.5% {
    clip-path: circle(20% at 100% 50%);
  }
  75% {
    clip-path: circle(10% at 100% 0%);
  }
  87.5% {
    clip-path: circle(5% at 50% 0%);
  }
}

.sharedSearch {

  padding-left: 0;
  min-width: 225px;

  .searchInput {
    border-radius: 4px;
    padding: 5px 5px 5px 25px;
    width: 100%;
    font-size: large;
    box-shadow: inset 0px 3px 2px #DDD;
    border: 1px solid #DDD;
    -webkit-appearance: none !important;
    text-overflow: ellipsis;
  }

  .searchButton {
    display: none;
  }
}

.tone-image-holder {
  position: relative;
  width: 100%;

  app-play-button {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 0;
    width: 0;
    padding: 12%;
    margin: auto;

    .anchor {
      position: initial;
    }

    .playback-button {
      border-radius: 50% !important;
      .mat-button-ripple {
        border-radius: inherit;
      }
    }
  }
}

app-mini-title-bar {
  display: block;
  height: 100%;
  overflow: hidden;
}

.mini-title-bar-container {
  position: relative;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  top: 0px;
}

// Everything about Dialogs

// The container of a dialog.
.mat-dialog-container,
.mat-mdc-dialog-container {
  // We overwrite this by 80vw, which gives a better result on small screens.
  // For larger screens we override it below...
  max-width: 80vw;

  // With the upgrade to angular material 2.0.0-beta.8 the position of the 
  // dialog is too close to the bottom in the desktop version
  position: fixed;
  height: auto !important;
  top: 5vh;
  // margin-left: -40vw !important;

  // Fix for Edge (The grey overlay doesn't allow to interact with the popup)
  z-index: 1000;

  .dialog,
  .mdc-dialog {
    width: 100%;
    display: block;

    .mat-radio-label {
      // Line breaks on radio button lables suck. But not showing full purchase options is even worse.
      // See ERBT-3804
      white-space: normal;
      // If multiple lines are required, make sure there is no extra whitespace between them.
      line-height: 1em;
    }

    // Fix the scrolling in the vertical axis if we put an error message in a dialog
    // See: https://github.com/angular/material2/issues/4168
    .mat-dialog-content,
    .mat-mdc-dialog-content {
      overflow: inherit;
      padding-bottom: 5%;
    }
  }
  .mat-dialog-actions .mat-button-base+.mat-button-base, .mat-dialog-actions 
    .mat-mdc-button-base+.mat-mdc-button-base {
    // Fix issue with extra space on the left side of the second dialog button.
    margin-left: 0px;
  }
}

.error-message {
  color: red;
}

.cookieBar {
  position: fixed;
  background-color: #FFFFFF;
  bottom: 0;
  width: 100%;
  height: 30px;
  padding-top: 22px;
  padding-bottom: 22px;
  text-align: center;
  font-size: 12px;
  border-top: 1px solid #5e646b;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  z-index: 1;

  span {
    margin-left: 1%;

    a {
      text-decoration: none;
      border-bottom: 1px solid #757C84;
      color: #383f47;
    }

    button {
      padding: 8px 20px;
      background-color: #FFFFFF;
      border: 1px solid #757C84;
      color: #383f47;
      cursor: pointer;
      line-height: 1em;
      text-align: center;
    }
  }
}

.search-recommendations {  
    display: block;
    text-align: center;
    margin-top: 0px !important;
    font-size: 18px !important;
}

.footerContent {
  display: inline;
}

.searchIcon {
  display: inline-block;
  height: 20px;
  width: 20px;
  padding-right: 5px;
  vertical-align: middle;
}

.rbt-version-container {
  display: none;
}

// On larger screens we want to use a fixed size for the dialog.
// Note that for displays with a width of 420px, 90vw == 378px.
@media only screen and (min-device-width: 420px) {
  .mat-dialog-container,
  .mat-mdc-dialog-container {
    // Originally we used max-width: 328px on the (nested) .dialog.
    // In order to keep this behavior for big screens,
    // we need to add 2 x 24px padding to get the size of .mat-dialog-container.
    // Then we round the number a bit to make it divisible by 9
    // (because it should be 90% of the min-device-width used in the media selector, see above).
    max-width: 378px !important;

    // With the upgrade to angular material 2.0.0-beta.8 the position of the 
    // dialog is too close to the bottom in the desktop version
    top: 20vh;
  }
}

@media only screen and (min-width: 768px) {

  .shared-ugc-image-holder {
    width: auto !important;
    max-width: none !important;
    max-height: none !important;
  }
  
  .tone-image-holder {
    width: 30%;
    max-width: 277px;
    max-height: 277px;
  }

  .floating-confirm-button {
    display: none;
  }

  .library-setting {
    height: 100%;
    overflow: hidden;

    .list-end {
      margin-bottom: 0px;
    }

    .confirm-button-top {
      display: block;
    }
  }

  .mini-title-bar-container {

    position: absolute;
    padding-left: 20%;
    padding-right: 20%;
    max-width: 60%;
    overflow: hidden;
    height: 100%;

    .logo-holder {
      position: relative;
      margin-top: 37px;
    }
  }

  .skin-content {
    &.skin-football, &.skin-halloween {
      margin-top: 50px !important;
    }
  }

  .landing-promo-background {

    .mat-mdc-list {
      width: 100%;
    }
    .mat-sidenav-container {
      // ERBT-5324: Originally we used 'unset' CSS keyword which is a convinient way to reset a property
      // to it's default initial value but it's not supported by IE. 
      // In this case we set the display property to it's initial value 'inline'.
      // https://developer.mozilla.org/en-US/docs/Web/CSS/display
      // https://developer.mozilla.org/en-US/docs/Web/CSS/unset
      display: inline;
    }
  
    .top-menu {
      display: none;
    }

    .mat-list-item,
    .mat-mdc-list-item {
      border: none !important;
      border-left: none !important;
      float: none;
      min-width: 100%;
      max-width: 100%;
      margin-top: 8px;
      margin-bottom: 8px; 
    }

    .mat-list-item-content,
    .mat-mdc-list-item-content,
    .mdc-list-item__content {
      padding: 0 25% !important;
    }
  
    p {
      color: #FFFFFF;
    }

    span.sms-code {
      color: #FFFFFF;
    }

    p.title {
      font-size: 32px;
    }
  
    p.description {
      font-size: 28px;
      line-height: 100%;
      padding-bottom: 3%;
    }

    .track-title, .artist, .sms, span.mat-button-wrapper {
      font-size: 18px;
    }

    .track-title, .artist {
      padding-bottom: 5px !important;
      max-width: 245px;
    }

    .cover-anchor {
      min-height: 90px;
      min-width: 90px;
    }

    .middle {
      margin-top: 15px;
      margin-bottom: 15px;
      padding-left: 30px;
    }

    .end {
      margin-top: 15px;
      // For displaying long artist and title text in landing page on Internet Explorer.
      // flex:1 it doesn't work reliably on IE
      flex: 1 1 auto !important;
    }

    span.mat-button-wrapper {
      font-size: larger;
      font-weight: bolder;
    }

    .button-less-list {
      app-igt-list-item, app-tone-list-item {
        &:nth-child(2), &:first-child {
          .mat-list-item {
            border-top: none !important;
          }
        }
        &:nth-child(2n+1) {
          .mat-list-item {
            border-left: none !important;
          }
        }
      }
    }
  
  }
}

.mat-list-item-content {
  // Set the minumum height for list item content.
  min-height: 48px !important;
}

@media (max-width: 768px) {

  .mat-autocomplete-panel.mat-autocomplete-panel-below {
    top: 10px !important;
    left: -4px;
    max-width: 102%;
    width: 102% !important;
  }

  .cookieBar {
    height: 60px;
  }

  .account-information-column {
    width: 45%;

    .bt-main {
      margin-top: 5%;
      margin-bottom: 5%;
    }
  }

}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .sharedSearch {
    .searchInput {
      width: calc(100% - 30px);
    }
  }

  .days-list {

    .mat-slide-toggle {
      width: 100%;
    }

  }
}

.tile-box {
  $margin-tile: 5px;
  $color-text: #FFFFFF;

  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d !important;
  -webkit-transform-style: preserve-3d !important;
  -moz-transform-style: preserve-3d !important;
  font-size: 12px;

  .tile {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    color: rgb(0, 0, 0);
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
  }

  .tile-back-overlay {
    width: 100%;
    height: 100%;
    background:rgba(0,0,0,0.6);
    position: absolute;
    z-index: 1;
  }

  .tile-front-overlay {
    display: none;
  }

  .tile-back {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    position: relative;

    img {
      transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      z-index: -1;
    }
  }

  .tile-text-holder {
    z-index: 1;
    text-align: left;
    position: absolute;
    top: $margin-tile;
    left: $margin-tile;
    max-width: 95%;

    .tile-title {
      font-weight: bold;
      color: $color-text;
    }

    .tile-artist {
      font-weight: normal;
      color: $color-text;
    }
  }

  .tile-button {
    z-index: 1;
    position: absolute !important;
    height: auto;
    right: $margin-tile;
    bottom: $margin-tile;
    min-width: 0px !important;

    .mat-raised-button {
      min-width: 0px !important;
      padding: 0px 8px !important;
      max-height: 20px !important;
      line-height: 18px !important;
    }

    .mat-button-wrapper {
      display: flex;
    }
  }

  .tile-player-holder {
    z-index: 1;
    position: absolute;
    left: $margin-tile;
    bottom: $margin-tile;
    height: 30px;
    width: 30px;

    .anchor {
      min-width: 30px;
      min-height: 30px;
    }

    .setting-preview {
      height: 100%;
      width: 100%;
      display: initial;
      padding: 0px;

      // All the possible icons (play, stop, disabled)
      * {
        background-size: contain !important;
      }
    }
  }

  .tile-price {
    text-align: right;
    color: $color-text;
  }

}

.skin-mobile-overlay {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &.skin-xmas {
    background-image: url('../assets/common/skins/snow.gif');
    background-size: repeat;
  }
  &.skin-valentine {
    background-image: url('../assets/common/skins/valentine.gif');
    background-size: repeat;
  }
}

.skin-mobile-background {
  z-index: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &.skin-xmas {
    background-image: url('../assets/common/skins/bg_snow.jpg');
    background-size: cover;
  }
  &.skin-santa {
    background-image: url('../assets/common/skins/bg_snow.jpg');
    background-size: cover;
  }
  &.skin-valentine {
    background-color: #FFFFFF;
  }
  &.skin-santa-rudolf {
    background-image: url('../assets/common/skins/bg_black_snow.jpg');
    background-size: cover;
  }
}

.skin-web-overlay {
  z-index: 5;
  pointer-events: none;
  &.skin-xmas {
    background-image: url('../assets/common/skins/snow.gif');
    background-size: repeat;
  }
  &.skin-valentine {
    background-image: url('../assets/common/skins/valentine.gif');
    background-size: repeat;
  }
  &.skin-santa {
    background: no-repeat right url('../assets/common/skins/santa.gif');
    max-width: 1000px;
    background-size: contain;
    margin: auto;
    right: 0;
  }
  &.skin-santa-rudolf {
    background: no-repeat right url('../assets/common/skins/santa-rudolf.gif');
    max-width: 1000px;
    background-size: contain;
    margin: auto;
    right: 0;
  }
}

.skin-content {
  &.skin-bunny {
    background: no-repeat center/100% url('../assets/common/skins/bunny.gif');
    height: 20vw;
    max-height: 140px;
    max-width: 700px;
    margin: auto;
  }
  &.skin-football {
    background: no-repeat center/100% url('../assets/common/skins/football.gif');
    height: 20vw;
    max-height: 140px;
    max-width: 700px;
    margin: auto;
  }
  &.skin-halloween {
    background: no-repeat center/100% url('../assets/common/skins/halloween.gif');
    height: 20vw;
    max-height: 140px;
    max-width: 700px;
    margin: auto;
  }
}


@media only screen and (max-width: 767.9px) {
  .searchInput {
    // Fix to keep the search box to it's original position under the header.
    position: absolute !important;
  }
}

// Iphone 4
@media only screen and (max-device-width: 320px) and (max-device-height: 480px) {

  // [General] Hides the lateral sidenav when the user wants to close it
  mat-sidenav.mat-sidenav.mat-sidenav-over.mat-sidenav-closing {
    display: none;
  }

  // [General] Helps to position the icons of the title bar
  br {
    clear:both;    
  }

  // [General] Preserves the style of the search textbox
  .searchInput {
    top: 0px !important;
    position: relative !important;
  }

  // [General] Preserves the style of the search textbox
  .shared-search {
    margin-top: 0px !important;
  }

  // [General] Preserves the style of the search textbox
  .sharedSearch {
    height: 40px !important;    
  }

  // [Shop page] Aligns to the middle the information of the tone (title, etc.) 
  // in the list-view of the tones
  div.middle {
    position: absolute !important;
    top: 10px !important;
    left: 90px !important;
  }

  // [Shop page] Aligns to the right button and the price of the tones 
  // in the list-view of the tones
  div.end {
    position: absolute !important;
    top: 30px !important;
    right: 10px !important;
  }

  // [Detail page] Aligns the price and the buy button
  b.price {
    margin-left: 40% !important;
  }

  // [Detail page] Aligns the price and the buy button
  button.buy-button.mat-primary.mat-raised-button {
    position: absolute !important;
    margin-top: 5% !important;
    left: 25% !important; 
  }

  // [Detail page] Deletes a large space between the content and the footer
  mat-sidenav-container.mat-sidenav-container.mat-sidenav-transition {
    min-height: 0vh !important;
  }

  // [Detail page] Deletes a large space between the content and the footer
  .wrapper {
    min-height: 0vh !important;
  }
}

@media (max-width: 320px) {
  .account-information {
    padding-left: 0%;
    display: block;
    div {
      display: block;
      div {
        display: block;
      }
    }
  }

  .account-information-column {
    width: 100%;
    text-align: center;
  }
}

.shared-ugc-image-holder {
  width: auto !important;
  max-width: auto !important;
  max-height: auto !important;
}

.middle-list-container ul {
	margin: 0 3% 10% 3%;
}

.middle-list-container ul li:first-child {
	background: -moz-linear-gradient(top, #ffffff 0%, #e6e6e6 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#e6e6e6)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #ffffff 0%,#e6e6e6 100%); /* Chrome10+,Safari5.1+ */
	-webkit-border-top-left-radius: 5px;
	-webkit-border-top-right-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-topright: 5px;
	border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-left: 15px;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  font-weight: bold;
}

.middle-list-container ul li:last-child {
	-webkit-border-bottom-left-radius: 5px;
	-webkit-border-bottom-right-radius: 5px;
	-moz-border-radius-bottomleft: 5px;
	-moz-border-radius-bottomright: 5px;
	border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.middle-list-container ul li {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 15px;
  font-weight: bold;
	border-bottom: 1px solid #d0d0d0;
	border-left: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
  word-break: break-all;
}

.middle-list-container ul li span {
	display:block;
  background-repeat: no-repeat;
  word-break: break-word;
  background-size: 1.5em 1.5em;
  background-position: left;
  padding: 10px 0 10px 35px;
}

.middle-list-container ul li span.item-1 {
	background-image: url("../assets/common/ic_voice.png");
}

.middle-list-container ul li span.item-2 {
	background-image: url("../assets/common/ic_stats.png");
}

.middle-list-container ul li span.item-3 {
	background-image: url("../assets/common/ic_group.png");
	vertical-align: middle;
}

.middle-list-container ul li:first-child {
	border-top: 1px solid #d0d0d0;
}

.middle-list-container ul {
  list-style: none;
}

.shared-ugc-image-holder img {
  border-radius: 0.4em;
}


// *** Custom styling for outlined input fields *** 
// mat-form-field-outline border thickness
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 1px !important;
}

// mat-form-field-outline disable hover effect
.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
    transition: 0ms !important;
}

.mat-mdc-form-field-flex {
  padding: 0 .75em 0 .75em;
    margin-top: -0.25em;
    position: relative;
    height:45px;
}

// mat-form-field-outline custom height
.mat-form-field-appearance-outline .mat-form-field-infix, 
.mdc-text-field--outlined .mat-mdc-form-field-infix {
  border: none !important;
  padding: 0.76em 0 0.76em 0 !important;
}

// mat-form-field-outline floating placeholder custom position
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.24em) scale(.78);
}

input.mat-input-element {
  padding-top: 0.5em !important;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 2.22em !important;
}

// Fix for long error messages that overlaped with the next element
.mat-form-field-appearance-outline.mat-form-field .mat-form-field {
  &-underline {
    position: static;
  }
  &-subscript-wrapper {
    position: static;
  }
}

.mat-mdc-floating-label {
  top: 1.3em;
}

.mat-mdc-form-field {
  margin-bottom: 20px;
}